import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import Login from './components/Login'; 
import Dashboard from "./components/Dashboard";
import CollectionView from './components/CollectionView';
import EntryForm from './components/EntryForm';
import Explore from './components/Explore';
import OfflinePage from './components/OfflinePage'; // Import the offline page component
import OfflineEntriesModal from './components/OfflineEntriesModal'; // Import the modal component
import Chat from './components/Chat'; // Import the modal component
import Profile from './components/Profile'; // Import the modal component
import ContentMap from './components/ContentMap'; // Import the modal component


const App = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [showModal, setShowModal] = useState(false);
  const [offlineEntries, setOfflineEntries] = useState([]);

  useEffect(() => {
    const setOnline = () => {
      setIsOnline(true);
      // Check for offline entries when coming back online
      const savedEntries = JSON.parse(localStorage.getItem('offlineEntries') || '[]');
      if (savedEntries.length > 0) {
        setOfflineEntries(savedEntries);
        setShowModal(true);
      }
    };
    
    const setOffline = () => setIsOnline(false);

    window.addEventListener('online', setOnline);
    window.addEventListener('offline', setOffline);

    // Check for offline entries when the app component mounts
    const savedEntries = JSON.parse(localStorage.getItem('offlineEntries') || '[]');
    if (savedEntries.length > 0) {
      setOfflineEntries(savedEntries);
      setShowModal(true);
    }

    return () => {
      window.removeEventListener('online', setOnline);
      window.removeEventListener('offline', setOffline);
    };
  }, []);

  const handleModalSubmit = () => {
    localStorage.removeItem('offlineEntries');
    setShowModal(false);
    setOfflineEntries([]);
    // Optionally, trigger a refresh of entries in your dashboard component
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  if (!isOnline) {
    return <OfflinePage />;
  }


  return (
    <>
      {showModal && (
        <OfflineEntriesModal
          offlineEntries={offlineEntries}
          onSubmit={handleModalSubmit}
          onClose={handleModalClose}
        />
      )}
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/notebooks" element={<CollectionView />} />
        <Route path="/create-entry" element={<EntryForm />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/contentMap" element={<ContentMap />} />
        <Route path="/explore" element={<Explore />} />
        <Route path="/geist/:username" element={<Profile />} />
      </Routes>
    </Router>
    </>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
