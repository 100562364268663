import React, { useState, useEffect } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';
import { NavLink, useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import bg from '../images/bg.jpg';
import logo from '../images/logo192.png'; // Replace 'your-logo.png' with the actual filename

import './Login.css';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null); // State for error message

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate('/dashboard');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const onLogin = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        navigate('/dashboard');
        console.log(user);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        setError('Invalid email or password. Please try again.'); // Set error message
      });
  };

  return (
    <>
      <main>
        <div className="container-fluid custom-container"
>
          <div className="row align-items-center"> {/* Center vertically */}
            {/* Left Side (Image) */}
            <div className="col-md-6 login-left">
              <img className="login-left-img" src={bg} />
            </div>
            {/* Right Side (Login Form) */}
            <div className="col-md-6 login-right">
              <form id="loginForm" style={{ maxWidth: '348px', margin: '0 auto', border: '1px solid rgb(20,20,20)', padding: '20px', borderRadius: '8px', border: 'var(--bs-card-border-width) solid var(--bs-card-border-color)', backgroundColor:'rgb(53,53,53)' }}>
              <div className="logo-container" style={{marginLeft: 'auto', marginRight: 'auto'}}>
                <img src={logo} alt="Logo" style={{ width: '64px', height: '64px', marginBottom: '24px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
              </div>
              <h2 className="text-center" style ={{marginBottom: '16px'}} >Welcome Back</h2>
                <div className="mb-3">
                  <label htmlFor="email-address" className="form-label">
                    Email address
                  </label>
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    className="form-control"
                    required
                    placeholder="Email address"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    Password
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    className="form-control"
                    required
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                {error && (
                  <div className="alert alert-danger" role="alert">
                    {error}
                  </div>
                )}
                <div className="text-center">
                <div style={{ display: 'flex', flexDirection: 'column' }}>

                  <button id="loginButton"
                    className="btn btn-primary"
                    onClick={onLogin}
                    style={{ width: '100%', borderColor:'rgb(121,84,180)', backgroundColor: 'rgb(121, 84, 180)'}}>
                    Login
                  </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Login;
